<template>
  <section class="partners-section container my-5">
    <h2 class="text-center mb-4">Nos Partenaires & Clients</h2>
    <div class="row justify-content-center">
      <div v-for="(partner, index) in partners" :key="index" class="col-6 col-md-4 col-lg-3 mb-4 text-center">
        <img :src="partner.logo" :alt="`Logo de ${partner.name}`" class="partner-logo">
      </div>
    </div>
  </section>
</template>

<script>
import brsPartenaire from '@/assets/partenaires/brs.png';
import applePartenaire from '@/assets/partenaires/apple.png';
import cocaPartenaire from '@/assets/partenaires/coca.png';
import fedexPartenaire from '@/assets/partenaires/fedex.png';
import jaguarPartenaire from '@/assets/partenaires/jaguar.png';
import nikePartenaire from '@/assets/partenaires/nike.png';
import starbucksPartenaire from '@/assets/partenaires/starbucks.png';

export default {
  data() {
    return {
      partners: [
        { name: 'Apple', logo: applePartenaire },
        { name: 'BRS', logo: brsPartenaire },
        { name: 'Coca-Cola', logo: cocaPartenaire },
        { name: 'FedEx', logo: fedexPartenaire },
        { name: 'Jaguar', logo: jaguarPartenaire },
        { name: 'Nike', logo: nikePartenaire },
        { name: 'Starbucks', logo: starbucksPartenaire }
      ]
    };
  }
};
</script>

<style scoped>
.partners-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partner-logo {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.partner-logo:hover {
  transform: scale(1.1);
}

h2 {
  font-size: 2em;
  color: #007bff; /* Couleur du titre */
  margin-bottom: 30px;
}
</style>

<template>
  <footer class="footer bg-dark text-white py-5">
    <div class="container">
      <div class="row">
        <!-- Section À propos -->
        <div class="col-md-4 mb-4">
          <h5>À PROPOS</h5>
          <p>
            Notre mission est de garantir des services de transport, transit, fret, et assistance escale de qualité, en assurant la sécurité et l'efficacité pour vos besoins logistiques.
          </p>
          <!--<img src="logo.png" alt="Logo de la société" class="logo-footer">  Placeholder pour le logo -->
        </div>

        <!-- Section Contact info -->
        <div class="col-md-4 mb-4">
          <h5>CONTACT INFO</h5>
          <p>
            Siège social : 15 rue du canal, 94000 - Créteil.
            <br />
            Siren : 910143866
            <br />
            Email : contact@citl.com
          </p>
        </div>

        <!-- Section Liens rapides -->
        <div class="col-md-4 mb-4">
          <h5>LIENS RAPIDES</h5>
          <ul class="list-unstyled">
            <li><a href="#transport" class="text-white">Services de Transport</a></li>
            <li><a href="#transit" class="text-white">Transit de Marchandises</a></li>
            <li><a href="#fret" class="text-white">Services de Fret</a></li>
            <li><a href="#assistance" class="text-white">Assistance Escale</a></li>
          </ul>
          <div class="social-icons mt-3">
            <a href="#" class="text-white me-2"><i class="fab fa-facebook"></i></a>
            <a href="#" class="text-white me-2"><i class="fab fa-instagram"></i></a>
            <a href="#" class="text-white me-2"><i class="fab fa-linkedin"></i></a>
          </div>
        </div>
      </div>

      <!-- Footer bottom row -->
      <div class="row mt-4">
        <div class="col text-center">
          <p class="mb-0">&copy; Site web crée par MADMIT</p>
        </div>
      </div>
    </div>

    <!-- Bouton retour vers le haut -->
    <button class="back-to-top" @click="scrollToTop">
      <i class="fas fa-arrow-up"></i>
    </button>
  </footer>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped>
.footer {
  background-color: #121212;
}

.logo-footer {
  max-width: 150px;
  margin-top: 10px;
}

.social-icons a {
  font-size: 1.5em;
}

.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #007bff; /* Bleu sobre pour la flèche */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-to-top:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .footer .row {
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }
}
</style>

<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
    <div class="container">
      <!-- Logo -->
      <a class="navbar-brand" href="#">
        CITL
        <!--<img src="logo.png" alt="Logo" class="logo" />  Placeholder pour ton logo -->
      </a>
      
      <!-- Burger button for mobile -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Nav links -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" href="#">Accueil</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Offres d'emploi</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">À propos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="scrollToContact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavbarComponent",
  methods: {
    scrollToContact() {
      // Faire défiler jusqu'à la section contact (formulaire)
      document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style scoped>
/* Style du logo */
.logo {
  max-width: 150px; /* Ajuste cette valeur en fonction de la taille de ton logo */
}

/* Style des liens de navigation */
.navbar-nav .nav-link {
  color: #000; /* Couleur du texte noir */
  font-size: 1em; /* Taille standard du texte */
  margin-left: 20px; /* Espacement entre les liens */
  transition: color 0.3s ease; /* Animation douce pour le survol */
}

/* Hover effect */
.navbar-nav .nav-link:hover {
  color: #007bff; /* Changement de couleur au survol */
  text-decoration: underline; /* Soulignement au survol */
}

/* Pour mobile : s'assure que les liens ne prennent pas trop de place */
@media (max-width: 992px) {
  .navbar-nav .nav-link {
    margin-left: 0;
    text-align: center;
    padding: 10px 0;
  }
}

/* Style pour la barre fixe */
.navbar {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Légère ombre pour un effet de profondeur */
}
</style>

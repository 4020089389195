<template>
  <section class="contact-form-section">
    <div class="container">
      <!-- Titre du formulaire -->
      <h2 class="form-title text-center mb-4">Contactez-nous</h2>

      <!-- Formulaire de contact -->
      <form @submit.prevent="submitForm" class="contact-form" id="contact-section">
        <!-- Champ Nom -->
        <div class="mb-4">
          <label for="name" class="form-label">Nom</label>
          <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Votre nom" required />
        </div>

        <!-- Champ Email -->
        <div class="mb-4">
          <label for="email" class="form-label">Email</label>
          <input type="email" id="email" v-model="form.email" class="form-control" placeholder="Votre email" required />
        </div>

        <!-- Champ Message -->
        <div class="mb-4">
          <label for="message" class="form-label">Message</label>
          <textarea id="message" v-model="form.message" class="form-control" rows="5" placeholder="Votre message" required></textarea>
        </div>

        <!-- Bouton d'envoi -->
        <button type="submit" class="btn btn-primary btn-block" :disabled="sending">
          {{ sending ? 'Envoi en cours...' : 'Envoyer' }}
        </button>

        <!-- Messages d'erreur et de succès -->
        <div v-if="error" class="alert alert-danger mt-4">{{ error }}</div>
        <div v-if="success" class="alert alert-success mt-4">Message envoyé avec succès !</div>
      </form>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
      sending: false,
      success: false,
      error: null,
    };
  },
  methods: {
    async submitForm() {
      this.sending = true;
      this.success = false;
      this.error = null;

      const templateParams = {
        name: this.form.name,
        email: this.form.email,
        message: this.form.message,
      };

      try {
        // Envoi de l'email avec EmailJS
        await emailjs.send(
          'service_beypqiy',   // Ton Service ID EmailJS
          'template_4cumfud',  // Ton Template ID EmailJS
          templateParams,
          'EYa181PNPlpcDGU2Y'  // Ton User ID EmailJS (Public Key)
        );

        // Affiche le message de succès après l'envoi
        this.success = true;
      } catch (err) {
        // Capture et affiche l'erreur si l'envoi échoue
        this.error = 'Une erreur est survenue lors de l\'envoi. Veuillez réessayer.';
      } finally {
        // Fin de l'état d'envoi
        this.sending = false;
      }
    }
  }
};
</script>

<style scoped>
/* Style global pour la section */
.contact-form-section {
  background-color: #f8f9fa;
  padding: 60px 0;
}

/* Titre du formulaire */
.form-title {
  font-size: 2.5em;
  font-weight: 600;
  color: #007bff;
  position: relative;
}

.form-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: #007bff;
  margin: 10px auto 0;
  border-radius: 5px;
}

/* Formulaire */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Style des champs de saisie */
.form-control {
  height: 50px;
  font-size: 1.1em;
  padding: 10px 15px;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Champ de texte pour le message */
textarea.form-control {
  resize: none;
}

/* Style du bouton d'envoi */
.btn {
  width: 100%;
  padding: 12px;
  font-size: 1.2em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Styles pour les messages d'erreur et de succès */
.alert {
  font-size: 1em;
}

.alert-success {
  color: #28a745;
}

.alert-danger {
  color: #dc3545;
}
</style>

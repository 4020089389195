<template>
  <section class="mission-carousel my-5">
    <div class="container">
      <!-- Titre "À propos" -->
      <h2 class="section-title text-center mb-5">À propos</h2>

      <div id="missionCarousel" class="carousel slide" data-bs-ride="carousel">
        <!-- Indicateurs de slide -->
        <div class="carousel-indicators">
          <button v-for="(service, index) in services" :key="index" type="button" :data-bs-target="'#missionCarousel'" :data-bs-slide-to="index" :class="{ active: index === 0 }" aria-current="true" :aria-label="`Slide ${index + 1}`"></button>
        </div>

        <!-- Slides du carrousel -->
        <div class="carousel-inner">
          <div v-for="(service, index) in services" :key="index" :class="['carousel-item', { active: index === 0 }]">
            <div class="d-flex flex-column align-items-center text-center p-4">
              <img :src="service.icon" alt="Service Icon" class="service-icon mb-4 animate-icon">
              <h3 class="service-title">{{ service.title }}</h3>
              <p class="service-description">{{ service.description }}</p>
            </div>
          </div>
        </div>

        <!-- Boutons précédent/suivant -->
        <button class="carousel-control-prev" type="button" data-bs-target="#missionCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Précédent</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#missionCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Suivant</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import transportIcon from '@/assets/icons/transport.png';
import logistiqueIcon from '@/assets/icons/logistique.png';
import transitIcon from '@/assets/icons/transit.png';
import fretIcon from '@/assets/icons/fret.png';
import escaleIcon from '@/assets/icons/escale.png';

export default {
  data() {
    return {
      services: [
        {
          title: 'Transport',
          description: 'Nous assurons le transport sécurisé de vos marchandises, en optimisant les délais de livraison.',
          icon: transportIcon
        },
        {
          title: 'Logistique',
          description: 'Une gestion complète et intégrée de vos opérations logistiques pour un service fluide.',
          icon: logistiqueIcon
        },
        {
          title: 'Transit',
          description: 'Nous facilitons le transit de vos biens, en prenant soin de toutes les démarches nécessaires.',
          icon: transitIcon
        },
        {
          title: 'Fret',
          description: 'Un service de fret performant pour le transport aérien ou maritime de vos marchandises.',
          icon: fretIcon
        },
        {
          title: 'Assistance Escale',
          description: 'Services d’assistance à l’escale, garantissant la sécurité et l’efficacité à chaque étape.',
          icon: escaleIcon
        }
      ]
    };
  }
};
</script>

<style scoped>
/* Style de la section du carrousel */
.mission-carousel {
  max-width: 100%;
  margin: 0 auto;
}

/* Style du titre "À propos" */
.section-title {
  background-color: #007bff;
  font-size: 2.5em;
  font-weight: 600;
  color: #333;
  position: relative;
}

.section-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: #007bff;
  margin: 10px auto 0;
  border-radius: 2px;
}

/* Style du carrousel */
.carousel-inner {
  background-color: #f8f9fa; /* Couleur neutre et sobre */
  border-radius: 10px;
}

.carousel-item {
  height: 350px;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transition: opacity 0.5s ease; /* Transition douce entre les slides */
}

.carousel-item.active {
  display: flex;
  opacity: 1;
}

.service-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  transition: transform 0.5s ease;
}

.service-title {
  font-size: 1.5em;
  color: #007bff;
  margin-bottom: 10px;
}

.service-description {
  font-size: 1.1em;
  color: #333;
  max-width: 600px;
  margin: 0 auto;
}

/* Animation sur les icônes */
.animate-icon:hover {
  transform: scale(1.1);
}

/* Style des flèches */
.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.carousel-control-prev, .carousel-control-next {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  transition: transform 0.3s ease;
}

.carousel-control-prev:hover, .carousel-control-next:hover {
  transform: scale(1.1);
}
</style>

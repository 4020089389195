<template>
  <div id="app">

    <NavBar />
    <!-- Hero Section -->
    <HeroSection />

    <!-- Main Content -->
    <main class="container my-5">
      <h2>Bienvenue chez CITL Tchad</h2>
      <p>
        CITL Tchad est spécialisé dans le transport logistique, le transit fret, et l'assistance escale. 
        Nous assurons la sécurité et la rapidité de vos marchandises à chaque étape de leur transport.
      </p>

      <!-- Services Section -->
      <ServicesSection />

       <!-- Mission Carousel Section -->
      <MissionCarousel />

      <!-- Partenaires Section -->
      <PartenairesSection />

      <!-- Formulaire de contact -->
      <ContactForm />
    </main>

    <!-- Footer -->
    <footer class="bg-dark text-white p-4 text-center">
      <FooterSection />
    </footer>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import ContactForm from './components/ContactForm.vue'; // Import du formulaire
import HeroSection from './components/Hero_Section.vue'; // Import du Hero Section
import ServicesSection from './components/ServicesSection.vue'; // Import des Services
import PartenairesSection from './components/PartenairesSection.vue'; // Import des Partenaires
import MissionCarousel from './components/MissionCarousel.vue';
import FooterSection from './components/Footer_Section.vue';


export default {
  components: {
    ContactForm,
    HeroSection,
    ServicesSection,
    PartenairesSection, // Ajout du nouveau composant
    MissionCarousel,
    FooterSection,
    NavBar
  },
};
</script>

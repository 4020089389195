<template>
  <section class="services-section container my-5">
    <div class="row">
      <div v-for="(service, index) in services" :key="index" class="col-md-4 mb-4 d-flex justify-content-center">
        <div class="service-card p-4 text-center">
          <img :src="service.icon" alt="Service Icon" class="service-icon mb-3">
          <h4>{{ service.title }}</h4>
          <p>{{ service.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import transportIcon from '@/assets/icons/transport.png';
import logistiqueIcon from '@/assets/icons/logistique.png';
import transitIcon from '@/assets/icons/transit.png';
import fretIcon from '@/assets/icons/fret.png';
import escaleIcon from '@/assets/icons/escale.png';

export default {
  data() {
    return {
      services: [
        {
          title: 'Transport',
          description: 'Nous transportons vos marchandises en toute sécurité, quel que soit le lieu de destination.',
          icon: transportIcon
        },
        {
          title: 'Logistique',
          description: 'Gestion complète de la chaîne logistique, du départ à la livraison finale.',
          icon: logistiqueIcon
        },
        {
          title: 'Transit',
          description: 'Un service rapide et fiable pour le transit de vos marchandises à travers les frontières.',
          icon: transitIcon
        },
        {
          title: 'Fret',
          description: 'Nous assurons le fret de vos marchandises par voie aérienne ou maritime.',
          icon: fretIcon
        },
        {
          title: 'Opérateur Assistance Escale',
          description: 'Services d’assistance pour vos marchandises dans les aéroports et les ports.',
          icon: escaleIcon
        }
      ]
    };
  }
};
</script>

<style scoped>
.services-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f9fa;
  width: 100%;
  max-width: 300px;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  width: 80px;
  height: 80px;
}

h4 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

p {
  font-size: 1em;
  line-height: 1.5;
}
</style>

<template>
  <section class="hero-section d-flex align-items-center justify-content-center">
    <div class="overlay"></div> <!-- Superposition sombre -->
    <div class="text-center content">
      <h1>Transport logistique, transit fret & opérateur assistance escale</h1>
      <p class="mt-3">Nous transportons vos marchandises en toute sécurité, partout dans le monde</p>
      <button class="btn btn-primary mt-4" @click="scrollToContact">Contactez-nous</button>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToContact() {
      // Faire défiler jusqu'à la section contact (formulaire)
      document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.hero-section {
  height: 100vh;
  position: relative;
  background: url('@/assets/hero_background.png') no-repeat center center/cover;
  
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Superposition sombre avec opacité */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Ombre portée sur le texte */
}

h1 {
  font-weight: 700; /* Texte épais */
  font-size: 3em; /* Taille du texte plus grande */
}

p {
  font-size: 1.2em;
  font-weight: 500;
}

.btn {
  padding: 10px 20px;
  font-size: 1.2em;
}
</style>
